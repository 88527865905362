
// COMPONENTS

// APP
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";


interface UserAccess {
	creditPointsOptions: boolean;
}

export default defineComponent({
	name: "UserAccess",
	components: {
	},
	props: {
		userId: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const store = useStore();

		const updateAccessModulesButton = ref<HTMLElement | null>(null);

		const userAccess = ref<UserAccess>({
			creditPointsOptions: false,
		});

		let user = ref(null);

		onMounted(async () => {
			setCurrentPageBreadcrumbs("Overview", ["Account"]);

			let userProfile = await store.dispatch(Actions.USER_PROFILE, { user_id: props.userId });

			if (userProfile != undefined && Object.prototype.hasOwnProperty.call(userProfile, 'code') && userProfile.code == 200) {
				userAccess.value.creditPointsOptions = (userProfile.data.access.creditPointsOptions != undefined && userProfile.data.access.creditPointsOptions == 1) ? true : false;
				console.log(userAccess.value.creditPointsOptions);
			}
		});


		const updateAccessModules = async () => {
			if (updateAccessModulesButton.value) {
				// Activate indicator
				updateAccessModulesButton.value.setAttribute("data-kt-indicator", "on");
				
				const payload = {
					user_id : props.userId,
					data: {
						credit_points_options : (userAccess.value.creditPointsOptions) ? 1 : 0
					}
				};

				ApiService.setHeader();
				const resp = await ApiService.post("user/access/update", payload)
					.then(({ data }) => {
						console.log(data);
						if (data.code == 401) {
							showAlert('error', 'Unauthorized');
						}else{
							showAlert('success', 'Successfully updated access');
						}
						updateAccessModulesButton.value?.removeAttribute("data-kt-indicator");
						return true;
					})
					.catch(({ response }) => {
						//console.log(response);
					});
				
			}
		};

		const showAlert = (icon, message) =>  {
			Swal.fire({
				text: message,
				icon: icon,
				confirmButtonText: "Ok",
				buttonsStyling: false,
				customClass: {
					confirmButton: "btn btn-light-primary",
				},
			});
		};

		return {
			user,
			userAccess,
			updateAccessModules,
			updateAccessModulesButton
		};
	},
});
